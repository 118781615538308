import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import SelectCountries from "./select"
import { WhiteButton, ContactButton } from "./buttons"
import { Card } from "./card"
import useCards from "../hooks/use-cards"
import { IconHi } from "./icons"

const Contact = () => {
  const data = useStaticQuery(graphql`
    query MyQueryContact {
      allContentfulContact {
        nodes {
          title
          subtitle
        }
      }
    }
  `)
  const { title, subtitle } = data.allContentfulContact.nodes[0]
  const cards = useCards()
  const cardsFiltered = cards.filter(card => card.link !== "/contact")
  return (
    <>
      <div className="w-72 lg:w-full lg:max-w-xl flex flex-col items-center space-y-4">
        <div className="flex flex-col ">
          <div className="flex justify-end -mt-8 transform translate-y-3 lg:-translate-x-52 ">
            <IconHi />
          </div>
          <div>
            <h1 className="">{title}</h1>
            <p className="subtitle mt-2">{subtitle}</p>
          </div>
        </div>
        <form
          action="https://getform.io/f/6152e63f-968f-4ea9-9d53-dfa86409f281"
          method="POST"
          className="w-72 lg:w-full lg:max-w-xl h-full flex flex-col justify-center text-neutral-02"
        >
          <label className="label" for="name">
            Name:
          </label>
          <input type="text" name="name" className="input" required />

          <label className="label" for="email">
            Email address:
          </label>
          <input type="email" name="email" className="input" required />

          <label className="label" name="country">
            Country / Region:
          </label>
          <SelectCountries className="input" />

          <label className="label" for="message">
            Message:
          </label>
          <textarea rows="4" cols="50" name="message" className="input" />
          <div className="ml-auto mt-8">
            <ContactButton text="Send" />
          </div>
        </form>
        <div className="flex flex-col lg:hidden">
          {cardsFiltered.map(card => (
            <Card
              key={card.id}
              image={card.icon}
              text={card.cardText}
              link={card.link}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default Contact
