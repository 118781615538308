import * as React from "react"

import Layout from "../components/layout"
import Contact from "../components/contact"

const ContactPage = () => (
  <Layout page="Contact">
    <Contact />
  </Layout>
)

export default ContactPage
